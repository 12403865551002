import React from 'react'
import {Image, Container, Row, Col, Card, CardGroup} from 'react-bootstrap'
import {Parallax, ParallaxBanner, BannerLayer} from 'react-scroll-parallax'
import { MDBContainer} from 'mdbreact'
import homePage from '../../images/homePage.jpg'
import Fscene from '../../images/homePage_1scene.png'
import Sscene from '../../images/homePage_2scene.png'
import Bscene from '../../images/homePage_background.png'

import pricesSmall from '../../images/PricesSmall.png'
import pricesMedium from '../../images/PricesMedium.png'
import pricesLarge from '../../images/PricesLarge.png'
import './landing.css'

const LandingPage = () => {
    return (
        <div>



            <section id="secHome">
            <ParallaxBanner
                layers={[
                    {
                        image: Bscene,
                        amount: 0.5,
                    },
                    {
                        image: Fscene,
                        amount: 0.2,
                    },
                    {
                        image: Sscene,
                        amount: 0.1,
                    },
                ]}
                style={{
                    height: '650px',
                }}
            >
            </ParallaxBanner>
            </section>





            <section id="secPrices" className="pricesGroup">
            <Container >
                <CardGroup>
                    <Card border="light" className="text-center" bg="dark" text="white">
                        <Card.Header>Small</Card.Header>
                        <Card.Img className="cardImage mx-auto d-block"
                            variant="top"
                            src={pricesSmall} />
                        <Card.Body>
                        <Card.Text>
                            This feature contains
                            <ul>
                                <li><strong>500</strong> requests</li>
                                <li><strong>1</strong> user</li>
                            </ul>
                        </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            1267.99 PEN/month
                        </Card.Footer>
                    </Card>
                    <Card border="light" className="text-center" bg="dark" text="white">
                        <Card.Header>Medium</Card.Header>
                        <Card.Img variant="top"  className="cardImage mx-auto d-block" src={pricesMedium} />
                        <Card.Body>
                        <Card.Text>
                            This feature contains
                            <ul>
                                <li><strong>1500</strong> requests</li>
                                <li><strong>2</strong> user</li>
                            </ul>
                        </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            1984.99 PEN/month
                        </Card.Footer>
                    </Card>
                    <Card border="light" className="text-center" bg="dark" text="white">
                        <Card.Header>Large</Card.Header>
                        <Card.Img variant="top" className="cardImage mx-auto d-block" src={pricesLarge} />
                        <Card.Body>
                        <Card.Text>
                            This feature contains
                            <ul>
                                <li>Requests <strong>UNLIMITED</strong></li>
                                <li><strong>5</strong> user</li>
                            </ul>
                        </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            2000.99 PEN/month
                        </Card.Footer>
                    </Card>
                </CardGroup>
            </Container>
            </section>





            <section id="secHowItWorks" className='contentBanner'>
                <ParallaxBanner
                    layers={[
                        {
                            image: Bscene,
                            amount: 0.1,
                        },
                        {
                            image: Fscene,
                            amount: 0.2,
                        },
                        {
                            children: (
                                <div style={{paddingTop: '10em',}}>
                                    <EmbedsPage></EmbedsPage>
                                </div>
                            ),
                            amount: 0
                        },
                    ]}
                    style={{
                        height: '600px',
                    }}
                >
                </ParallaxBanner>
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/JJJxhYaqQPA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </section>





            <section id="secContact" className="footerPage">
                <Container >
                    <Row>
                        <Col xs={8}>
                            <h1>VISION CCAL</h1>
                            <p>Contenido descriptivo de informacion de contactos</p>
                        </Col>
                        <Col>
                            <h3>Links</h3>
                            <ul>
                                <li><a href="https://www.facebook.com/visionccal">Facebook</a></li>
                                <li><a href="https://visionccal.com/">WebPage</a></li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>Contact Info</h3>
                            <ul>
                                <li>Mail: asistencia@visionccal.com</li>
                                <li>Phone: +51 996 804 462</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                
            </section>
            <div className="footerSign">
                    © 2020 Copyright: ThadorCons
            </div>
        </div>
    )
}

const EmbedsPage = () => {
    return (
        <MDBContainer>
            <div className="embed-responsive embed-responsive-16by9 contentBanner">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/JJJxhYaqQPA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </MDBContainer>
    )
  }


export default LandingPage