import React from 'react'
import {Navbar, Container, Nav, Form, Button} from 'react-bootstrap'
import logo_solo from '../../../images/new_logo.png'

// buscar parallax js


const Navigation = () =>{
    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg" >
                <Container>
                    <Navbar.Brand href="#secHome">
                        <img
                            src={logo_solo}
                            width="60"
                            height="30"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#secHome">About us</Nav.Link>
                        <Nav.Link href="#secPrices">Prices</Nav.Link>
                        <Nav.Link href="#secHowItWorks">How it works</Nav.Link>
                        <Nav.Link href="#secContact">Contact Us</Nav.Link>
                    </Nav>
                    <Form className="d-flex">
                        <Button variant="outline-light">Login</Button>
                    </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navigation
