
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './components/landingPage'
import Navigation from './components/landingPage/navigation';

function App() {
  return (
    <div>
      <header>
        <Navigation></Navigation>
      </header>
      <LandingPage></LandingPage>
    </div>
  );
}

export default App;
